/* tslint:disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';

import createClient from './apolloClient';

// Setup interationalisation
import { addLocaleData, IntlProvider } from 'react-intl';
import fr from 'react-intl/locale-data/fr';
import messages from './locales/message';
const supportedLanguages = ['fr-FR', 'mg-MG'];

let locale: string =
  (navigator.languages && navigator.languages[0]) || navigator.language || 'fr-FR';

if (!supportedLanguages.find((l) => l === locale)) {
  locale = 'fr-FR';
}

if (localStorage.getItem('language')) {
  locale = localStorage.getItem('language') || '';
}
addLocaleData([...fr]);

const token = localStorage.getItem('access_token');
const client = createClient(token || '');

ReactDOM.render(
  <ApolloProvider client={client}>
    <IntlProvider locale={locale} messages={(messages as any)[locale]}>
      <App />
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
