import { API_URL } from './config';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import resolvers from './apollo/resolvers';
import typeDefs from './apollo/typeDefs';

const createClient = (token: string) => {
  const httpLink = createHttpLink({
    uri: `${API_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    typeDefs,
    resolvers,
    // defaults,
  });
};

export default createClient;
