import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { PrivateRoute } from './components/PrivateRoute';

const SignIn = Loadable({
  loader: () => import('./components/Authentication/Signin/Signin'),
  loading: () => <div>Loading...</div>,
});

const Dashboard = Loadable({
  loader: () => import('./components/Dashboard/Dashboard'),
  loading: () => <div>Loading...</div>,
});

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/login" component={SignIn} />
        <PrivateRoute exact={true} path="/" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
